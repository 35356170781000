var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { loadingSlice } from './LoadingStore';
import { userSlice } from './UserStore';
import { bonusingSlice } from './BonusingStore';
export var defaultSweepState = {
    lastRequested: null, // possibly use this to trigger reloading?
    requestError: false,
    sweeps: [],
    sweepDetails: {},
    active: [],
    current: [],
    past: [],
    allocatedDetails: [],
    playerAllocatableEntries: [],
};
// REDUCERS
// basic actions that affect this object only
var getSweepReducer = function (state, action) {
    var sweeps = action.payload;
    if (sweeps) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), sweeps: sweeps });
    }
    else {
        return state;
    }
};
var resetReducer = function (state, action) {
    return __assign({}, defaultSweepState);
};
var getActiveSweepsReducer = function (state, action) {
    var active = action.payload;
    if (active) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), active: active });
    }
    else {
        return state;
    }
};
var getCurrentSweepsReducer = function (state, action) {
    var current = action.payload;
    if (current) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), current: current });
    }
    else {
        return state;
    }
};
var getPastSweepsReducer = function (state, action) {
    var _a;
    var oldPastData = (_a = state.past.data) !== null && _a !== void 0 ? _a : [];
    var past = action.payload;
    if (past) {
        past.data = __spreadArray(__spreadArray([], oldPastData, true), past.data, true);
        return __assign(__assign({}, state), { lastRequested: Date.now(), past: past });
    }
    else {
        return state;
    }
};
var getPlayerAllocatableEntriesReducer = function (state, action) {
    var allocatableEntries = action.payload;
    if (allocatableEntries) {
        return __assign(__assign({}, state), { playerAllocatableEntries: allocatableEntries });
    }
    else {
        return state;
    }
};
var getSweepDetailsLoggedInReducer = function (state, action) {
    var _a;
    var sweepDetails = (_a = action === null || action === void 0 ? void 0 : action.payload) === null || _a === void 0 ? void 0 : _a[0];
    if (sweepDetails) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), sweepDetails: sweepDetails });
    }
    else {
        return state;
    }
};
var getSweepDetailsReducer = function (state, action) {
    var sweepDetails = action.payload;
    if (sweepDetails) {
        return __assign(__assign({}, state), { lastRequested: Date.now(), sweepDetails: sweepDetails });
    }
    else {
        return state;
    }
};
var allocateEntriesReducer = function (state, action) {
    var allocateEntriesDetails = action.payload;
    var currentAllocatableDetails = JSON.parse(JSON.stringify(state.allocatedDetails));
    if (currentAllocatableDetails.length) {
        for (var _i = 0, currentAllocatableDetails_1 = currentAllocatableDetails; _i < currentAllocatableDetails_1.length; _i++) {
            var obj = currentAllocatableDetails_1[_i];
            if (obj.entryId === allocateEntriesDetails.entryId) {
                obj.entryId = allocateEntriesDetails.entryId;
                obj.sweepId = allocateEntriesDetails.sweepId;
                obj.submitted = allocateEntriesDetails.submitted;
                obj.error = allocateEntriesDetails.submitted;
                obj.data = allocateEntriesDetails.result;
                break;
            }
            else {
                currentAllocatableDetails.push(allocateEntriesDetails);
            }
        }
        return __assign(__assign({}, state), { allocatedDetails: currentAllocatableDetails });
    }
    else {
        var newDetails = [];
        newDetails.push(allocateEntriesDetails);
        return __assign(__assign({}, state), { allocatedDetails: newDetails });
    }
};
var clearSweepReducer = function (state, action) {
    return defaultSweepState;
};
var setRequestErrorReducer = function (state, action) {
    return __assign(__assign({}, state), { lastRequested: Date.now(), requestError: true });
};
var clearAllocatableDetailsReducer = function (state, action) {
    var currentAllocatableDetails = state.allocatedDetails;
    var sweepToClear = action.payload;
    if (currentAllocatableDetails.length) {
        for (var _i = 0, currentAllocatableDetails_2 = currentAllocatableDetails; _i < currentAllocatableDetails_2.length; _i++) {
            var details = currentAllocatableDetails_2[_i];
            if (details.sweepId === sweepToClear.sweepId) {
                details = {};
            }
        }
        return __assign(__assign({}, state), { allocatedDetails: currentAllocatableDetails });
    }
    else {
        return __assign(__assign({}, state), { allocatedDetails: [] });
    }
};
export var sweepSlice = createSlice({
    name: 'sweep',
    initialState: defaultSweepState,
    reducers: {
        getSweeps: getSweepReducer,
        getActiveSweeps: getActiveSweepsReducer,
        getCurrentSweeps: getCurrentSweepsReducer,
        getPastSweeps: getPastSweepsReducer,
        getSweepDetailsLoggedIn: getSweepDetailsLoggedInReducer,
        getSweepDetails: getSweepDetailsReducer,
        allocateEntries: allocateEntriesReducer,
        clearSweep: clearSweepReducer,
        setRequestError: setRequestErrorReducer,
        clearAllocatableDetails: clearAllocatableDetailsReducer,
        getPlayerAllocatableEntries: getPlayerAllocatableEntriesReducer,
        reset: resetReducer,
    },
    extraReducers: (_a = {},
        _a[userSlice.actions.logout] = function (state) {
            return defaultSweepState;
        },
        _a),
});
// ACTUAL ACTIONS
// a place to make the actions more complex if necessary
var getSweeps = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getSweeps' }));
                return [4 /*yield*/, apiClient().getSweeps(payload)];
            case 1:
                result = _a.sent();
                dispatch(sweepSlice.actions.getSweeps(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getSweeps' }));
                return [2 /*return*/];
        }
    });
}); };
var getActiveSweeps = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getActiveSweeps' }));
                return [4 /*yield*/, apiClient().getActiveSweeps(payload)];
            case 1:
                result = _a.sent();
                dispatch(sweepSlice.actions.getActiveSweeps(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getActiveSweeps' }));
                return [2 /*return*/];
        }
    });
}); };
var getCurrentSweeps = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getCurrentSweeps' }));
                return [4 /*yield*/, apiClient().getCurrentSweeps(payload)];
            case 1:
                result = _a.sent();
                dispatch(sweepSlice.actions.getCurrentSweeps(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getCurrentSweeps' }));
                return [2 /*return*/];
        }
    });
}); };
var getPastSweeps = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getPastSweeps' }));
                return [4 /*yield*/, apiClient().getExpiredSweeps(payload)];
            case 1:
                result = _a.sent();
                dispatch(sweepSlice.actions.getPastSweeps(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getPastSweeps' }));
                return [2 /*return*/];
        }
    });
}); };
var getSweepDetailsLoggedIn = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getSweepDetailsLoggedIn' }));
                return [4 /*yield*/, apiClient().getSweeps(payload)];
            case 1:
                result = _a.sent();
                dispatch(sweepSlice.actions.getSweepDetailsLoggedIn(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getSweepDetailsLoggedIn' }));
                return [2 /*return*/];
        }
    });
}); };
var getSweepDetails = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getSweepDetails' }));
                return [4 /*yield*/, apiClient().getSweepDetails(payload)];
            case 1:
                result = _a.sent();
                dispatch(sweepSlice.actions.getSweepDetails(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getSweepDetails' }));
                return [2 /*return*/];
        }
    });
}); };
var allocateEntries = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'allocateEntries' }));
                return [4 /*yield*/, apiClient().allocateEntries(payload)];
            case 1:
                result = _a.sent();
                dispatch(sweepSlice.actions.allocateEntries(result));
                // Reveal games should have already been played by the time user allocates, so just clear them here.
                dispatch(bonusingSlice.actions.clearPendingGames(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'allocateEntries' }));
                return [2 /*return*/];
        }
    });
}); };
var getPlayerAllocatableEntries = function (dispatch, payload, apiClient) { return __awaiter(void 0, void 0, void 0, function () {
    var result;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                dispatch(loadingSlice.actions.setLoading({ action: 'getPlayerAllocatableEntries' }));
                return [4 /*yield*/, apiClient().getPlayerAllocatableEntries(payload)];
            case 1:
                result = _a.sent();
                dispatch(sweepSlice.actions.getPlayerAllocatableEntries(result));
                dispatch(loadingSlice.actions.unsetLoading({ action: 'getPlayerAllocatableEntries' }));
                return [2 /*return*/];
        }
    });
}); };
//actions that get exported to the views
export var sweepActions = function (dispatch, apiClient) {
    return {
        getSweeps: function (payload) { return getSweeps(dispatch, payload, apiClient); },
        getSweepDetails: function (payload) { return getSweepDetails(dispatch, payload, apiClient); },
        getSweepDetailsLoggedIn: function (payload) { return getSweepDetailsLoggedIn(dispatch, payload, apiClient); },
        allocateEntries: function (payload) { return allocateEntries(dispatch, payload, apiClient); },
        getActiveSweeps: function (payload) { return getActiveSweeps(dispatch, payload, apiClient); },
        getCurrentSweeps: function (payload) { return getCurrentSweeps(dispatch, payload, apiClient); },
        getPastSweeps: function (payload) { return getPastSweeps(dispatch, payload, apiClient); },
        getPlayerAllocatableEntries: function (payload) { return getPlayerAllocatableEntries(dispatch, payload, apiClient); },
        clearAllocatableDetails: function (payload) { return dispatch(sweepSlice.actions.clearAllocatableDetails(payload)); },
        reset: function (payload) { return dispatch(sweepSlice.actions.reset(payload)); },
    };
};
