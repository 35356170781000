import React from 'react';
import { connect } from 'react-redux';
import { getCMSObject, PasswordExpiring } from 'sg-ui-components';
import { mapStateToProps, mapDispatchToProps } from '../Store';
import Layout from '../Components/Layout';
import UserLockout from '../utils/user_lockout';

const telescriptPasswordExpiring = getCMSObject('data.components.teleScripts.PasswordExpiring.jsonBlock');

const PasswordExpiringPage = ({ user, config, cmsSourceFirebase, actions, match }) => {
    return (
        <Layout {...{ user, config, cmsSourceFirebase, actions, match }}>
            <UserLockout>
                <PasswordExpiring
                    telescript={telescriptPasswordExpiring}
                    expirationDays={user.expiration_days ?? 0}
                    updateNowLink={'/my-profile'}
                    remindMeLaterLink={'/home'}
                />
            </UserLockout>
        </Layout>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordExpiringPage);
