//***********************************************************************************
//  Helper component to display Password Status
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const PasswordStatusForm = (status) => {
    if (status === 'initial') return null;

    return (
        <>
            {status === 'valid' ? (
                <div className='d-flex align-items-center theme-text-success pt-1' role='alert'>
                    <FontAwesomeIcon icon="fa-regular fa-circle-check" />
                    <small className='ml-2'>Your password matches the criteria.</small>
                </div>
            ) : (
                <div className='d-flex align-items-center theme-text-danger pt-1' role='alert'>
                    <FontAwesomeIcon icon="fa-regular fa-circle-xmark" />
                    <small className='ml-2'>{status}</small>
                </div>
            )}
        </>
    );
};
