import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps, mapDispatchToProps } from '../Store';

import { ContentBox, ContentBoxHead, ContentBoxBody, isBrowser } from 'sg-ui-components';
import { HashLink } from 'react-router-hash-link';

import UserLockout from '../utils/user_lockout';

//* Retrieve ID from url params
const queryString = isBrowser() ? window.location.search : '';
const urlParams = new URLSearchParams(queryString);
const queryParams = {
    passcode: urlParams.get('passcode'),
    mode: urlParams.get('mode'),
};

const AccountConfirm = ({ user, loading, actions }) => {
    const [mode, setMode] = useState('');

    const validateUser = async (params) => {
        if (user.verifySuccess) {
            setMode('success');
        }

        if (params.passcode) {
            await actions.userActions.confirm({ passcode: params.passcode });
        }

        if (user.lastServerResponse.type === 'confirm') {
            if (user.lastServerResponse.message.code !== '_NOT_FOUND') {
                setMode('success');
            }
        }
    };

    const resendConfirmEmail = async () => {
        await actions.loadingActions.setLoading({ action: 'confirmResend' });
        await actions.userActions.updateSection({ section: 'resend-confirm-email', status: 'initial' });

        const payload = {
            registrar_id: user.player.registrar_id ?? '',
        };

        await actions.userActions.confirmResend(payload);
    };

    useEffect(() => {
        validateUser(queryParams);
    }, [user.lastServerResponse.type]);

    return (
        <>
            <div className='my-4' />
            {!mode && (
                <div className='d-flex justify-content-center align-items-center'>
                    <div className='loading-indicator' role='status'>
                        <span className='sr-only'>Loading...</span>
                    </div>
                </div>
            )}
            {mode === 'confirm-email' && (
                <UserLockout disableRedirect='account-confirm'>
                    <ContentBox variant='theme-blue form-step mx-2'>
                        <ContentBoxHead className='registration-step-title'>Confirm Your Email</ContentBoxHead>
                        <ContentBoxBody>
                            <div className='inner-step'>
                                <div className='text-center'>
                                    {user.updateSection.section === 'resend-confirm-email' && user.updateSection.status === 'success' && (
                                        <div className='alert alert-success text-center' role='alert'>
                                            An email has been resent. Please follow the link in your email to confirm your account.
                                        </div>
                                    )}

                                    {user.updateSection.section === 'resend-confirm-email' && user.updateSection.status === 'error' && (
                                        <div className='alert alert-danger text-center' role='alert'>
                                            We could not send the email. Please, try again later.
                                        </div>
                                    )}

                                    <p>
                                        We’ve sent an email {user.player.email ? ` to ${user.player.email} ` : ``} for confirmation.
                                        <br />
                                        If you did not receive the email you can resend or contact us for help.
                                    </p>

                                    <button
                                        type='button'
                                        className='btn theme-btn theme-secondary btn-block my-4'
                                        onClick={() => resendConfirmEmail()}
                                        disabled={loading.actions['confirmResend']}>
                                        Resend Confirmation Email
                                    </button>

                                    <HashLink className='btn theme-btn theme-secondary btn-block my-4' to='/feedback#contact'>
                                        Contact Us
                                    </HashLink>

                                    <div className='my-2' />

                                    <HashLink to='/feedback#contact'>Wrong email? Click here to edit.</HashLink>
                                </div>
                            </div>
                        </ContentBoxBody>
                    </ContentBox>
                </UserLockout>
            )}

            {mode === 'help-to-verify' && (
                <ContentBox variant='theme-blue mx-2'>
                    <ContentBoxHead className='registration-step-title'>Can&apos;t find your email?</ContentBoxHead>
                    <ContentBoxBody>
                        <div className='inner-step'>
                            <div className='text-center'>
                                <p>
                                    It seems like you can’t find or did not receive the confirmation email we sent to username@domain.com.
                                    <br />
                                    Fill out a help form and we will contact you to verify.
                                </p>

                                <HashLink className='btn theme-btn theme-secondary btn-block my-4' to='/feedback#contact'>
                                    Contact Us
                                </HashLink>

                                <div className='my-2' />

                                <HashLink to='/feedback#contact'>Wrong email? Click here to edit.</HashLink>
                            </div>
                        </div>
                    </ContentBoxBody>
                </ContentBox>
            )}

            {mode === 'success' && (
                <UserLockout disableRedirect='account-confirm'>
                    <ContentBox variant='theme-blue mx-2'>
                        <ContentBoxHead className='registration-step-title'>EMAIL CONFIRMED!</ContentBoxHead>
                        <ContentBoxBody>
                            <div className='inner-step'>
                                <div className='text-center'>
                                    <img src='players-club-logo.png' className='img-fluid mx-auto my-3' />
                                </div>
                                <div className='text-center'>
                                    <p>
                                        Your email address has been confirmed! You&apos;re just two steps away from having access to all <i>My Lottery</i>{' '}
                                        Players Club features.
                                    </p>
                                    <HashLink className='btn theme-btn theme-secondary btn-lg' to='/login'>
                                        Continue
                                    </HashLink>
                                </div>
                            </div>
                        </ContentBoxBody>
                    </ContentBox>
                </UserLockout>
            )}
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountConfirm);
