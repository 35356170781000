import React, { useState } from 'react';
import eye from './eye-solid.svg';
import eyeSlash from './eye-slash-solid.svg';

export const PasswordField = ({ name, value, handleChange, placeholder = '', className, handleKeyUp }) => {
    const [type, setType] = useState('password');

    const handleClick = () => setType(type === 'text' ? 'password' : 'text');

    return (
        <div className={className}>
            <input
                type={type}
                className='form-control theme-input'
                id={name}
                value={value}
                name={name}
                onChange={handleChange}
                onBlur={handleKeyUp}
                aria-describedby={`${name}Help`}
                placeholder={placeholder}
            />
            <span className='password-show' onClick={handleClick}>
                {type === 'text' ? (
                    <span className='oi oi-eye'>
                        <img src={eye} />
                    </span>
                ) : (
                    <span className='oi oi-eye'>
                        <img src={eyeSlash} />
                    </span>
                )}
            </span>
        </div>
    );
};
