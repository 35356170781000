import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TableDesktop, TableMobile, formatDate, TeleScript } from 'sg-ui-components';

import { mapStateToProps, mapDispatchToProps } from '../../Store';
import ThirdPartyPrize from './ThirdPartyPrize';
import SecondChancePrize from './SecondChancePrize';

const MyPlayerPrizes = ({ prize, actions, telescript, thirdPartyPrizes, sweep, loading }) => {
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        async function fetchData() {
            if (!pageLoaded) {
                await actions?.prizeActions?.getPlayerPrizes();
                setPageLoaded(true);
            }
        }

        fetchData();
    }, []);

    if (loading?.actions?.['getPlayerPrizes']) {
        return (
            <React.Fragment key='loading-indicator-section'>
                <div className='d-flex justify-content-center bg-white'>
                    <div className='loading-indicator' role='status'>
                        <span className='sr-only'>Loading...</span>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    const prizes = prize?.playerPrizes?.length ? prize?.playerPrizes : [];

    if (!prizes.length) {
        return (
            <div>
                <p className='text-center'>
                    <TeleScript line={telescript?.noPrizes}>You currently have no prizes.</TeleScript>
                </p>
            </div>
        );
    }

    const entriesTable = (data) => {
        const entries = data ?? [];

        const displayHeaders = {
            promotion: telescript?.tablePromotion ?? 'Promotion',
            drawing: telescript?.tableDrawing ?? 'Drawing',
            drawingDate: telescript?.tableDrawingDate ?? 'Drawing Date',
            details: telescript?.tableDetails ?? 'DETAILS',
        };

        const displayRows = entries.map((entry) => ({
            ...entry,
            fields: {
                promotion: (entry.sweeps_type === 'pfd' ? entry.drawing_group : entry.sweepstake) ?? 'N/A',
                drawing: (entry.sweeps_type === 'pfd' ? entry.sweepstake : entry.drawing_group) ?? 'N/A',
                drawingDate: formatDate?.monthWithDotDayYear(entry.draw_date) ?? 'N/A',
                details: entry.coupon ? (
                    <ThirdPartyPrize entry={entry.coupon} telescript={telescript} thirdPartyPrizes={thirdPartyPrizes} actions={actions} />
                ) : (
                    <SecondChancePrize entry={entry} actions={actions} sweepstake_id={entry.sweepstake_id} sweep={sweep} telescript={telescript} />
                ),
            },
        }));

        const displayOptions = {
            tableId: 'my-second-chance-prizes-table',
            isLoaded: pageLoaded,
            messageNoRows: telescript?.messageNoRows ?? 'You currently have no prizes.',
        };

        return (
            <>
                <TableDesktop headers={displayHeaders} rows={displayRows} options={displayOptions} />
                <TableMobile headers={displayHeaders} rows={displayRows} options={displayOptions} />
            </>
        );
    };

    return (
        <>
            <div className='row'>
                <div className='col'>
                    {entriesTable(prizes)}
                    <TeleScript line={telescript?.disclamer}>
                        <p className='text-center'>Disclamer: If you have not been contacted for the prize, please contact the lottery directly.</p>
                    </TeleScript>
                </div>
            </div>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyPlayerPrizes);
